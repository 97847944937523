import React, { useState, useEffect } from 'react';
import Network from './Requests';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  setInfoProduct,
  setType,
  setListProduct,
  setCurrentProduct,
  setSave,
} from '../store/reducers/productSlice';
import { setOpenStore } from '../store/reducers/productStoreSlice';
import useInput from '../hooks/useInput';
import ModalStore from './ModalStore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { setOpenCopy } from '../store/reducers/modalSlice';
import axios from 'axios';
import { globalConfig, globalConfigUrl, defaultConfig } from '../configuration/config';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { setOpenError, setTextError } from '../store/reducers/errorSlice';
import ModalError from './ModalError';

export default function ProductInfo(props) {
  const dispatch = useDispatch();
  const [view, setView] = useState();
  const [pathEditor, setPathEditor] = useState();
  const [lightPresets, setLightPresets] = useState();
  const [iLightPreset, setILightPresets] = useState();
  const productList = useSelector((state) => state.product.list);
  const storeCurrent = useSelector((state) => state.store.current);
  const productCurrent = useSelector((state) => state.product.current);
  const productCurrentInfo = useSelector((state) => state.product.info);
  const save = useSelector((state) => state.product.save);
  const calculation = useInput(productCurrentInfo?.guidCalculation);
  const skyboxPath = useInput(productCurrentInfo?.skyboxPath);
  const iOsSkyboxPath = useInput(productCurrentInfo?.iOsSkyboxPath);
  const interactiveParameters = useInput(productCurrentInfo?.interactiveParameters);
  const sequenceHeight = useInput(productCurrentInfo?.sequenceHeight);
  const externalUrl = useInput(productCurrentInfo?.externalUrl);
  const type = useSelector((state) => state.product.type);

  useEffect(() => {
    const fetch = async () => {
      await axios.get(`${window.location.origin}/${globalConfigUrl}`).then((response) => {
        setPathEditor(response.data.common.editor);
      });
      let presents = await new Network().GetLightPresets(productCurrent);
      setLightPresets(presents);
    };
    fetch();
  }, []);
  useEffect(() => {
    const fetch = async () => {
      if (productCurrent) {
        const product = await new Network().Get(productCurrent);
        dispatch(setInfoProduct(product));
      }
    };
    fetch();
  }, [productCurrent]);

  useEffect(() => {
    const fetch = async () => {
      if (productCurrentInfo) {
        const icon = await new Network().CheckMainIconContainedInCalculation(
          productCurrentInfo?.guidCalculation,
        );
        if (!icon) {
          setView(true);
        } else {
          setView(false);
        }
      }
    };
    fetch();
  }, [productCurrentInfo]);

  const openStore = (e) => {
    dispatch(setType(e));
    dispatch(setOpenStore(true));
  };

  ////
  const [checked, setChecked] = React.useState(false);

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };
  ////

  const [open, setOpen] = React.useState();
  const [tooltip, setTooltip] = React.useState('Копировать');
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  });

  const [openSuccess, setOpenSuccess] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const [errortext, setText] = React.useState('');
  const { vertical, horizontal } = state;

  const copyProduct = () => {
    dispatch(setOpenCopy(true));
  };

  const saveOrUpdate = () => {
    const fetch = async () => {
      if (save) {
        let insert = {};
        insert.guidCalculation = calculation?.value;
        insert.skyboxPath = skyboxPath?.value;
        insert.iOsSkyboxPath = iOsSkyboxPath?.value;
        insert.interactiveParameters = interactiveParameters?.value;
        insert.sequenceHeight = sequenceHeight?.value;
        insert.externalUrl = externalUrl?.value;
        insert.idStore = storeCurrent?.id;
        insert.allowQuickLook = productCurrentInfo?.allowQuickLook
          ? productCurrentInfo?.allowQuickLook
          : false;
        insert.allowAppClip = productCurrentInfo?.allowAppClip
          ? productCurrentInfo?.allowAppClip
          : false;

        insert.dontShowiOsSkybox = productCurrentInfo?.dontShowiOsSkybox
          ? productCurrentInfo?.dontShowiOsSkybox
          : false;

        insert.startingWithAutoPlaneDetection = productCurrentInfo?.startingWithAutoPlaneDetection
          ? productCurrentInfo?.startingWithAutoPlaneDetection
          : false;

        insert.allowSceneViewer = productCurrentInfo?.allowSceneViewer
          ? productCurrentInfo?.allowSceneViewer
          : false;

        insert.allowWebXr = productCurrentInfo?.allowWebXr ? productCurrentInfo?.allowWebXr : false;

        insert.allowKtx2 = productCurrentInfo?.allowKtx2 ? productCurrentInfo?.allowKtx2 : false;
        insert.useSequence = productCurrentInfo?.useSequence
          ? productCurrentInfo?.useSequence
          : false;
        insert.enchanceQuality = productCurrentInfo?.enchanceQuality
          ? productCurrentInfo?.enchanceQuality
          : false;

        insert.useForAutoTest = productCurrentInfo?.useForAutoTest
          ? productCurrentInfo?.useForAutoTest
          : false;

        insert.iLightPreset = iLightPreset;

        Object.preventExtensions(insert);
        const send = await new Network().addProduct(insert);
        if (send?.status == 'error') {
          setText(send.text);
          // dispatch(setCurrentProduct(productList[0]));
          setOpen(true);
        } else {
          dispatch(setListProduct([send, ...productList]));
          dispatch(setSave(false));
          dispatch(setCurrentProduct(send?.token));
          setOpenSuccess(true);
        }
      } else {
        let insert = JSON.parse(JSON.stringify(productCurrentInfo));

        insert.guidCalculation = calculation?.value;
        insert.skyboxPath = skyboxPath?.value;
        insert.iOsSkyboxPath = iOsSkyboxPath?.value;
        insert.interactiveParameters = interactiveParameters?.value;
        insert.sequenceHeight = sequenceHeight?.value;
        insert.externalUrl = externalUrl?.value;

        insert.dontShowiOsSkybox = productCurrentInfo?.dontShowiOsSkybox
          ? productCurrentInfo?.dontShowiOsSkybox
          : false;

        insert.startingWithAutoPlaneDetection = productCurrentInfo?.startingWithAutoPlaneDetection
          ? productCurrentInfo?.startingWithAutoPlaneDetection
          : false;

        Object.preventExtensions(insert);
        const send = await new Network().UpdateProduct(insert);
        if (send?.status == 'error') {
          setText(send.text);
          setOpen(true);
        } else {
          dispatch(setSave(false));
          setOpenSuccess(true);
        }
      }
    };
    fetch();
  };

  const setInput = (e) => {
    if (type == 'web') {
      skyboxPath.onChange(e);
    } else if (type == 'ios') {
      iOsSkyboxPath.onChange(e);
    }
  };

  const testOpen = () => {};

  const copy = () => {
    setTooltip('Скопировано');
    window.navigator.clipboard.writeText(productCurrent);
  };

  const outFunc = () => {
    setTooltip('Копировать');
  };

  const changeInfo = (_nameProp, value) => {
    let clone = Object.assign({}, productCurrentInfo);
    const key = String(_nameProp);
    clone[key] = value;
    dispatch(setInfoProduct(clone));
  };

  const changeLightPreset = (e) => {
    const value = Number(e.target.value);
    let clone = Object.assign({}, productCurrentInfo);
    clone['iLightPreset'] = value;
    dispatch(setInfoProduct(clone));
    setILightPresets(value);
  };

  return (
    <div className="wrap productBlock">
      <ModalError />
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={7000}
        onClose={handleClose}>
        <div className="messageAl">
          <div className="messageAl--text">
            <span>
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="ErrorOutlineIcon">
                <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
              </svg>{' '}
            </span>
            <span>{errortext}</span>
          </div>
          <CloseIcon fontSize="small" onClick={handleClose} />
        </div>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={7000}
        onClose={handleCloseSuccess}>
        <div className="messageAlSuccses">
          <div className="messageAl--text">
            <span>
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="SuccessOutlinedIcon">
                <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path>
              </svg>{' '}
            </span>
            <span>Данные сохранены!!!</span>
          </div>

          <CloseIcon fontSize="small" onClick={handleCloseSuccess} />
        </div>
      </Snackbar>
      <ModalStore setInput={(e) => setInput(e)} />
      <div className="product-info">
        {productCurrentInfo?.autoTestStatus == 2 && (
          <>
            <div className="info-row">
              <div className="flex flex--ai-c">
                <div className="error-test">Тест не пройден {productCurrentInfo?.autoTestAt}</div>
                <a
                  className="button red"
                  onClick={() => {
                    dispatch(setOpenError(true));
                    dispatch(setTextError(productCurrentInfo?.autoTestErrorText));
                  }}>
                  Ошибка
                </a>
              </div>
            </div>
          </>
        )}
        <div className="info-row">
          <div className="flex flex--ai-c">
            <div>
              <div className="info-name">Расчёт(guidCalculation)</div>
              <div className="info-name">
                {productCurrentInfo?.name && productCurrentInfo?.name}
              </div>
            </div>
            <div className="padding-left">
              <a
                className="button"
                href={pathEditor + '?calculation=' + productCurrentInfo?.guidCalculation}
                target="_blank">
                В модификатор
              </a>
            </div>
          </div>
          <div className="info-inputs">
            <input type="text" placeholder="guidCalculation" {...calculation} />
          </div>
        </div>
        {!save && (
          <div className="info-row">
            <div className="info-name">Токен(token)</div>
            <div className="info-name">
              <span className="copyText">{productCurrent && productCurrent}</span>
              <span className="copyToken">
                <div className="tooltip" onClick={copy} onMouseOut={outFunc}>
                  {productCurrent && (
                    <>
                      <svg
                        version="1.1"
                        baseProfile="tiny"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24">
                        <g>
                          <g>
                            <path d="M13.904,23.375H6.1c-2.14,0-2.25-0.033-2.296-0.048c-0.146-0.044-0.302-0.165-0.392-0.255    c-0.104-0.105-0.26-0.357-0.283-0.532c-0.015-0.107-0.017-1.396-0.017-2.155V7.387c0-2.138,0.033-2.248,0.047-2.295    C3.202,4.948,3.321,4.791,3.411,4.7C3.515,4.595,3.77,4.436,3.948,4.414C4.072,4.399,5.763,4.398,6.1,4.398h2.491    c2.192,0,2.276,0.051,2.374,0.109c0.038,0.023,0.124,0.076,1.575,1.525l2.719,2.719c1.447,1.445,1.506,1.543,1.525,1.575    c0.028,0.046,0.054,0.089,0.073,0.357c0.023,0.051,0.036,0.106,0.036,0.165c0,0.044-0.007,0.087-0.02,0.126    c0.013,0.34,0.02,0.873,0.02,1.724v7.686c0,2.143-0.032,2.247-0.047,2.298c-0.044,0.145-0.164,0.301-0.254,0.391    c-0.103,0.103-0.354,0.26-0.53,0.284C15.947,23.373,14.515,23.375,13.904,23.375z M4.264,22.567c0.314,0,0.976,0.007,1.836,0.007    h7.804c1.047,0,1.953-0.004,2.069-0.012c-0.011,0.008,0.035-0.04,0.058-0.06c0.024-0.024,0.052-0.065,0.06-0.079    c-0.011-0.032,0.002-0.934,0.002-2.039v-7.686c0-0.589-0.006-1.087-0.015-1.449h-3.641c-1.323,0-2.4-1.077-2.4-2.4V5.214    c-0.363-0.009-0.86-0.015-1.447-0.015H6.1c-1.051,0-1.96,0.005-2.072,0.011c0.02,0.001-0.032,0.039-0.054,0.059    C3.951,5.292,3.922,5.334,3.915,5.348c0.012,0.02-0.003,0.834-0.003,2.039v12.998c0,1.046,0.005,1.952,0.012,2.068    c-0.002-0.035,0.042,0.038,0.061,0.061c0.023,0.022,0.063,0.051,0.077,0.058C4.067,22.568,4.137,22.567,4.264,22.567z     M10.838,5.476V8.85c0,0.882,0.718,1.6,1.6,1.6h3.375c-0.25-0.257-0.637-0.649-1.12-1.131L11.975,6.6    C11.499,6.124,11.1,5.73,10.838,5.476z" />
                          </g>
                          <g>
                            <path d="M19.241,19.602h-0.659c-0.221,0-0.4-0.18-0.4-0.4s0.179-0.4,0.4-0.4h0.659c0.328,0,0.64-0.004,0.716-0.011    c0.006-0.005,0.047-0.042,0.069-0.061c0.024-0.024,0.053-0.066,0.06-0.08c-0.011-0.031,0.002-0.933,0.002-2.038V8.926    c0-0.603-0.003-1.097-0.007-1.449h-3.648c-1.323,0-2.4-1.077-2.4-2.4V1.44c-0.363-0.009-0.86-0.015-1.447-0.015h-2.491    c-1.051,0-1.96,0.005-2.071,0.011C8.03,1.439,7.993,1.476,7.97,1.494c-0.024,0.024-0.053,0.066-0.06,0.08    c0.005,0.033-0.003,0.389-0.003,0.698v0.659c0,0.221-0.179,0.4-0.4,0.4s-0.4-0.18-0.4-0.4V2.272c0-0.298,0.006-0.817,0.047-0.954    c0.043-0.145,0.164-0.302,0.253-0.393c0.103-0.104,0.357-0.264,0.537-0.285c0.122-0.015,1.813-0.016,2.151-0.016h2.491    c2.192,0,2.276,0.051,2.374,0.109c0.037,0.022,0.124,0.075,1.575,1.525l2.719,2.719c1.55,1.55,1.574,1.646,1.6,1.757    c0.004,0.017,0.011,0.045,0.018,0.224c0.011,0.037,0.017,0.076,0.017,0.117c0,0.033-0.004,0.065-0.011,0.096    c0.006,0.306,0.011,0.833,0.011,1.754v7.686c0,2.143-0.032,2.247-0.047,2.298c-0.044,0.145-0.165,0.301-0.254,0.391    c-0.103,0.103-0.354,0.26-0.529,0.284C19.997,19.592,19.629,19.602,19.241,19.602z M14.833,1.702v3.374c0,0.882,0.718,1.6,1.6,1.6    h3.361c-0.254-0.268-0.637-0.662-1.106-1.131l-2.719-2.719C15.494,2.351,15.095,1.957,14.833,1.702z" />
                          </g>
                        </g>
                      </svg>
                      <span className="tooltiptext">{tooltip}</span>
                    </>
                  )}
                </div>
              </span>
              <a
                className="button"
                href={'https://widget.arigami.tech/v0/demo/' + productCurrent}
                target="_blank">
                Демо
              </a>
            </div>
          </div>
        )}

        <div className="info-row">
          <div className="info-name">Скайбокс-веб (skyboxPath)</div>
          <div className="info-inputs">
            <input type="text" placeholder="skyboxPath" {...skyboxPath} />
          </div>
          <div className="info-button">
            {/* <Button variant="contained">Загрузить с диска</Button> */}
            <Button variant="contained" onClick={() => openStore('web')}>
              Выбрать из готовых
            </Button>
          </div>
        </div>
        <div className="info-row">
          <div className="info-name">Скайбокс-iOS (iOsSkyboxPath)</div>
          <div className="info-inputs">
            <input type="text" placeholder="iOsSkyboxPath" {...iOsSkyboxPath} />
          </div>
          <div className="info-button">
            {/* <Button variant="contained">Загрузить с диска</Button> */}
            <Button variant="contained" onClick={() => openStore('ios')}>
              Выбрать из готовых
            </Button>
            <FormControlLabel
              control={
                <Checkbox
                  checked={productCurrentInfo?.dontShowiOsSkybox == true ? true : false}
                  onChange={(e) => changeInfo('dontShowiOsSkybox', e.target.checked)}
                />
              }
              label="Не применять (dontShowiOsSkybox)"
            />
          </div>
          <div className="info-name">InteractiveParameters</div>
          <div className="info-inputs">
            <input type="text" placeholder="interactiveParameters" {...interactiveParameters} />
          </div>
          <div className="info-name">sequenceHeight {''}</div>
          <div className="info-inputs">
            <input
              type="number"
              placeholder="sequenceHeight"
              {...sequenceHeight}
              className="input-number"
            />
          </div>
          <div className="info-button">
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    productCurrentInfo?.startingWithAutoPlaneDetection == true ? true : false
                  }
                  onChange={(e) => changeInfo('startingWithAutoPlaneDetection', e.target.checked)}
                />
              }
              label="iOS - начинать со сканирования плоскостей"
            />
          </div>
          <div className="info-button">
            <FormControlLabel
              control={
                <Checkbox
                  checked={productCurrentInfo?.allowSceneViewer == true ? true : false}
                  onChange={(e) => changeInfo('allowSceneViewer', e.target.checked)}
                />
              }
              label="allowSceneViewer"
            />
          </div>
          <div className="info-button">
            <FormControlLabel
              control={
                <Checkbox
                  checked={productCurrentInfo?.allowWebXr == true ? true : false}
                  onChange={(e) => changeInfo('allowWebXr', e.target.checked)}
                />
              }
              label="allowWebXr"
            />
          </div>
          <div className="info-button">
            <FormControlLabel
              control={
                <Checkbox
                  checked={productCurrentInfo?.allowKtx2 == true ? true : false}
                  onChange={(e) => changeInfo('allowKtx2', e.target.checked)}
                />
              }
              label="allowKtx2"
            />
          </div>
          <div className="info-button">
            <FormControlLabel
              control={
                <Checkbox
                  checked={productCurrentInfo?.useSequence == true ? true : false}
                  onChange={(e) => changeInfo('useSequence', e.target.checked)}
                />
              }
              label="useSequence"
            />
          </div>
          <div className="info-button">
            <FormControlLabel
              control={
                <Checkbox
                  checked={productCurrentInfo?.enchanceQuality == true ? true : false}
                  onChange={(e) => changeInfo('enchanceQuality', e.target.checked)}
                />
              }
              label="enchanceQuality"
            />
          </div>
          <div className="info-button">
            <FormControlLabel
              control={
                <Checkbox
                  checked={productCurrentInfo?.useForAutoTest == true ? true : false}
                  onChange={(e) => changeInfo('useForAutoTest', e.target.checked)}
                />
              }
              label="useForAutoTest"
            />
          </div>
        </div>
        <div className="info-row lightPreset">
          <div className="info-name">Предустановленный свет iOS</div>
          <div className="info-inputs">
            <FormControl>
              <NativeSelect value={productCurrentInfo?.iLightPreset} onChange={changeLightPreset}>
                {lightPresets?.map((i) => {
                  return <option value={i.value}>{i.name}</option>;
                })}
              </NativeSelect>
            </FormControl>
          </div>
        </div>
        <div className="info-row">
          <div className="info-name">URL карточки товара(externalUrl)</div>
          <div className="info-inputs">
            <input type="text" placeholder="externalUrl" {...externalUrl} />
          </div>
        </div>

        <div className="info-row">
          <FormControlLabel
            control={
              <Checkbox
                checked={productCurrentInfo?.allowQuickLook == true ? true : false}
                onChange={(e) => changeInfo('allowQuickLook', e.target.checked)}
              />
            }
            label="allowQuickLook"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={productCurrentInfo?.allowAppClip == true ? true : false}
                onChange={(e) => changeInfo('allowAppClip', e.target.checked)}
              />
            }
            label="allowAppClip"
          />
        </div>
        {view && (
          <div className="warning">
            <div className="warning-item">
              <span>Warning</span>Иконка отсутствует
            </div>
          </div>
        )}
        <div className="flex flex--jc-sb flex--ai-c">
          <div className="info-button">
            <Button className="info-save" variant="contained" onClick={copyProduct}>
              Скопировать товар
            </Button>
          </div>
          <div className="info-button">
            <Button className="info-save" variant="contained" onClick={saveOrUpdate}>
              Сохранить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
